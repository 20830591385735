export interface IAdminShopReportsRequest {
  fromDate?: Date
  toDate?: Date
  excludeBatch?: boolean
}

export interface IAdminShopReportsItem {
  id: string
  name: string
  nickname: string
  latitude: number
  longitude: number
  isVerified: boolean
  numberOfReports: number
}

export interface IAdminShopReportsResponse {
  shopReportsItems: IAdminShopReportsItem[]
}
