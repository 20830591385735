import { PosType } from '../enums/pos-type.enum'

export interface IRunShopImportRequest {
  shopIds: string[]
  startDate: Date
  endDate: Date
  forceApiSource: boolean
}

export interface IAdminGetPosTypeRequest {
  posType: PosType
}

export interface IAdminUpdatePosTypeRequest {
  posType: PosType
  tekmetricShopId?: number
  apiKey?: string
  connectionId?: string
  tireCategoryId?: string
  simplyGeniusShopId?: number
}

export interface IAdminPosTypeResponse {
  id?: string
  shopId?: string
  tekmetricShopId?: number
  apiKey?: string
  connectionId?: string
  tireCategoryId?: string
  shopName?: string
  simplyGeniusShopId?: number
}
