import { OrganizationFeatures } from '@mg-platform/users/users-data-access'
import {
  IOrganizationDetails,
  IAllOrganizationsResponse
} from '../../interfaces/organizations.interface'

export interface OrganizationsStateModel {
  organization: IOrganizationDetails
  allOrganizationsResponse: IAllOrganizationsResponse
  organizationFeatures: OrganizationFeatures[]
}
