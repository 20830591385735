import { IAdminCalendarFilters, IAdminCalendarResponse } from '../../interfaces/admin-calendar.interface'
import { IAdminShopReportsItem } from '../../interfaces/admin-shop-reports.interface'
import { ICountReportResponse } from '../../interfaces/count-report.interface'

export interface AdminReportsStateModel {
  calendarInfo: IAdminCalendarResponse
  calendarFilters: IAdminCalendarFilters
  countReportInfo: ICountReportResponse
  shopWithReports: IAdminShopReportsItem[]
}
