export interface ICountReportItem {
  total: number
  newInLastMonth: number
}

export interface ICountReportResponse {
  usersCount: ICountReportItem
  shopsCount: ICountReportItem
  organizationsCount: ICountReportItem
}
