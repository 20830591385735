export interface IAdminConfig {
  minAmountOfShopsForLocalMarket: number
  maxLocalMarketRangeInMeters: number
  shopVerificationEmail?: string
  suspiciousActivityWarningEmail?: string
  minShopsToShowMarketData: number
  maxDaysWithoutDailyReports: number
  maxSalesPerBay: number
  maxCarCountPerBay: number
  overMaxPercentage: number
}
