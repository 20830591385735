export interface IAdminCalendarRequest {
  fromDate?: Date
  toDate?: Date
  excludeBatch?: boolean
}

export interface IAdminCalendarDetail {
  day: Date
  count: string
}

export interface IAdminCalendarResponse {
  verifiedShopsCount: number
  reportsCount: IAdminCalendarDetail[]
}

export interface IAdminCalendarFilters {
  viewDate?: Date
}
